import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Spin } from '@sfx/react-ui';
import store from './redux/store';
import { Provider } from 'react-redux';
import { appConfiguration } from './config';
import * as serviceWorker from './serviceWorker';
import Auth0ProviderWithHistory from './auth0ProviderWithHistory';

const render = () => {
  const App = lazy(() => import('./App'));
  ReactDOM.render(
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<Spin size={0.2} />}>
            <Auth0ProviderWithHistory>
              <App />
            </Auth0ProviderWithHistory>
          </Suspense>
        </BrowserRouter>
      </Provider>
    </>,
    document.getElementById('root')
  );
};
appConfiguration.then(() => {
  render();
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
