import { createSlice } from '@reduxjs/toolkit';
import { themeOptions } from '../styles/theme/themeOptions';

const themeSlice = createSlice({
    name: 'theme',
    initialState: localStorage.getItem('theme') || 'dark',
    reducers: {
        toggleTheme: (state) => {
            const theme = state === 'dark' ? 'light' : 'dark'
            localStorage.setItem('theme', theme);
            handleThemeChange(theme !== 'light')
            return theme
        },
    }
})

export const handleThemeChange = (darkMode) => {
    let themeName = (darkMode ? 'dark' : 'light');
    // Logic here is based on the article 'Dynamic App Themes with CSS Variables and JavaScript' by Mike Wilcox
    const selectedTheme = themeOptions.find(t => t.name.toLowerCase() === themeName.toLowerCase()) || {};
    const html = document.getElementsByTagName('html')[0];
    Object.keys(selectedTheme).forEach((property, i) => {
        if (property === 'name') {
            return;
        }
        html.style.setProperty(property, selectedTheme[property]);
    });
}


const { actions, reducer } = themeSlice
export const { toggleTheme } = actions
export default reducer
