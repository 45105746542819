export const themeOptions = [
    {
        name: 'Dark',
        '--theme-logo-color': '#783cbd',
        '--theme-layout-background': '#1b3c44',
        '--theme-background': '#132e35',
        '--theme-background-grey': '#444444',

        '--theme-root-background': '#132e35',
        '--theme-box-background-primary': '#132e35',
        '--theme-box-background-secondary': '#1b3c44',
        '--theme-box-headerFooter-background': '#1b3c44',
        '--theme-box-headerFooter-border': '#132e35',
        '--theme-card-background': '#20515c',
        '--theme-card-border': '#132e35',
        '--theme-card-triangle': '#2c6d7c',
        '--theme-card-placeholder-background': '#1b3c44',
        '--theme-panel-card-border': '#20515c',

        '--theme-table-background-primary': '#1b3c44',
        '--theme-table-background-secondary': '#132e35',
        '--theme-table-border-hover': '#0097ba',
        '--theme-table-row-active': '#0097ba',
        '--theme-table-row-open': '#20515c',

        '--theme-modal-background': '#132e35',
        '--theme-modal-header': '#2c6d7c',
        '--theme-modal-border': '#2c6d7c',
        '--theme-modal-header-text': '#f4f4f4',
        '--theme-modal-content-border': '#20515c',

        '--theme-scroller-thumb': '#20515c',

        '--theme-surface-1': '#1b3c44',
        '--theme-surface-2': '#20515c',
        '--theme-surface-3': '#2c6d7c',
        '--theme-surface-4': '#cacaca',
        '--theme-surface-5': '#9aabaf',
        '--theme-surface-6': '#bdbdbd',

        '--theme-component-1': '#2c6d7c',
        '--theme-component-2': '#20515c',
        '--theme-component-3': '#1b3c44',
        '--theme-component-4': '#0089a9',
        '--theme-component-6': '#eed668', //banner
        '--theme-component-7': '#20515c',

        '--theme-outline-1': '#cacaca',
        '--theme-outline-2': '#9aabaf',
        '--theme-outline-3': '#9aabaf',
        '--theme-outline-4': '#f4f4f4',
        '--theme-outline-5': '#5d8b97',

        '--theme-text-primary': '#f4f4f4',
        '--theme-text-secondary': '#9aabaf',
        '--theme-text-tertiary': '#5d8b97',
        '--theme-text-disabled': '#9aabaf',
        '--theme-text-inverted': '#000000',
        '--theme-text-labels': '#667579',
        '--text-on-dark': '#f4f4f4',
        '--theme-text-tooltips': '#232323',
        '--theme-text-subtitle': '#9aabaf',

        '--theme-icon-1': '#2c6d7c',
        '--theme-icon-2': '#f4f4f4',
        '--theme-icon-3': '#20515c',
        '--theme-icon-4': '#2c6d7c',
        '--theme-icon-active': '#f4f4f4',
        '--theme-icon-inverted': '#000000',
        '--theme-icon-on-dark': '#f4f4f4',

        '--theme-waiting': '#979797',
        '--theme-processing': '#0097ba',
        '--theme-finished': '#5c9e43',

        '--headerPrimaryColor': '#20515c',
        '--headerSecondColor': '#2c6d7c',

        '--color-accent-1': '#0097ba',
        '--color-accent-2': '#2dc0e3',
        '--color-accent-3': '#0097ba',

        '--color-green-ok': '#5c9e43',
        '--color-red-critical': "#cd3127",
        '--color-blue-info': "#0497ba",
        '--color-yellow-warning': '#e9c51c',
        '--color-grey-unknown': '#667579',
        '--color-purple-complete': '#783cbd',

        '--theme-drop-down-menu-1': '#20515c',
        '--theme-drop-avatar-icon': '#0e2228',
        '--theme-drop-avatar-icon-back': '#1b3c44',

        '--theme-dropdown-menu': '#2c6d7c',
        '--theme-dropdown-menu-background': '#132e35',
        '--theme-dropdown-menu-border': '#2c6d7c',
        '--theme-dropdown-menu-outline': '#20515c',
        '--theme-dropdown-checkbox': '#20515c',
        '--theme-dropdown-menu-item-hover': '#0097ba',
        '--theme-dropdown-menu-item-selected': '#20515c',

        '--theme-dropdown-menu-avatar-text': '#9aabaf',
        '--theme-dropdown-hover': '#0097ba',
        '--theme-dropdown-selected': '#20515c',

        '--theme-input-background': '#20515c',
        '--theme-input-border-enabled': '#20515c',
        '--theme-input-text-enabled': '#9aabaf',
        '--theme-input-border-active': '#2c6d7c',

        '--button-solid-logout': '#0097ba',
        '--button-solid-logout-text': '#ffffff',
        '--button-solid-logout-hover': '#2c6d7c',
        '--button-solid-logout-text-hover': '#f4f4f4',
        '--button-solid-active': '#0097ba',
        '--button-solid-hover': '#2c6d7c',
        '--button-solid-focus': '#1b3c44',
        '--button-solid-disabled': '#1b3c44',

        '--button-solid-text-enabled': '#f4f4f4',
        '--button-solid-text-disabled': '#9aabaf',

        '--button-outline-background': '#132e35',
        '--button-outline-enabled-border': '#20515c',
        '--button-outline-hover-border': '#0097ba',
        '--button-outline-focus': '#0097ba',
        '--button-outline-disabled-border': '#20515c',

        '--button-outline-text-enabled': '#f4f4f4',
        '--button-outline-text-disabled': '#9aabaf',
        '--button-outline-text-hover': '#f4f4f4',
        '--button-outline-text-focus': '#f4f4f4',

        '--theme-checkbox-unchecked': '#20515c',
        '--theme-checkbox-border': '#20515c',
        '--theme-checkbox-border-hover': '#2c6d7c',
        '--theme-checkbox-border-focus': '#0097ba',
        '--theme-checkbox-disabled': '#1b3c44',
        '--theme-checkbox-border-disabled': '#1b3c44',

        '--theme-checkbox-checked': '#0097ba',
        '--theme-checkbox-checked-border-hover': '#2dc0e3',
        '--theme-checkbox-checked-border-focus': '#2c6d7c',

        '--theme-border-1': '#2c6d7c',
        '--theme-calendar-background': '#132e35',
        '--theme-calendar-headerText': '#667579',
        '--theme-calendar-dateSpan': '#20515c',

        '--theme-progress-background': '#9aabaf',
        '--theme-progress-no-cad-bg': 'linear-gradient(93deg, #5d8b97, #9aabaf)',
        '--theme-progress-stripe-color': '#909fa3',
        '--theme-progress-stripe-zero-color': '#7b878a',

        '--control-special-background': '#132e35',
        '--control-special-hover': '#2c6d7c',
        '--control-special-selected': '#20515c',
        '--control-special-border': '#20515c',

        // collapse
        '--theme-collapse-item-background': '#1c3c43',

        '--filter-primary-color': 'brightness(0) saturate(100%) invert(100%) sepia(31%) saturate(24%) hue-rotate(187deg) brightness(119%) contrast(91%)',
        '--filter-accent-color': 'brightness(0) saturate(100%) invert(56%) sepia(65%) saturate(5067%) hue-rotate(160deg) brightness(89%) contrast(102%)',
        '--filter-secondary-color': 'brightness(0) saturate(100%) invert(71%) sepia(14%) saturate(244%) hue-rotate(144deg) brightness(93%) contrast(86%)',
        '--filter-modalHeaderText-color': 'brightness(0) saturate(100%) invert(100%) sepia(31%) saturate(24%) hue-rotate(187deg) brightness(119%) contrast(91%)',
        '--filter-white-color': 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7498%) hue-rotate(53deg) brightness(103%) contrast(101%)'
    },
    {
        name: 'Light',
        '--theme-logo-color': '#783cbd',
        '--theme-layout-background': '#ffffff',
        '--theme-background': '#f4f4f4',
        '--theme-background-grey': '#444444',

        '--theme-root-background': '#e5e5e5',
        '--theme-box-background-primary': '#e5e5e5',
        '--theme-box-background-secondary': '#f4f4f4',
        '--theme-box-headerFooter-background': '#f4f4f4',
        '--theme-box-headerFooter-border': '#cacaca',
        '--theme-card-background': '#ffffff',
        '--theme-card-border': '#e5e5e5',
        '--theme-card-triangle': '#f4f4f4',
        '--theme-card-placeholder-background': '#f1fcff',
        '--theme-panel-card-border': '#e5e5e5',

        '--theme-table-background-primary': '#ffffff',
        '--theme-table-background-secondary': '#e5e5e5',
        '--theme-table-border-hover': '#0097ba',
        '--theme-table-row-active': '#0097ba',
        '--theme-table-row-open': '#f4f4f4',

        '--theme-modal-background': '#ededed',
        '--theme-modal-header': '#cacaca',
        '--theme-modal-border': '#cacaca',
        '--theme-modal-header-text': '#20515c',
        '--theme-modal-content-border': '#cacaca',

        '--theme-scroller-thumb': '#30657f',

        '--theme-surface-1': '#ffffff',
        '--theme-surface-2': '#f4f4f4',
        '--theme-surface-3': '#ededed',
        '--theme-surface-4': '#cacaca',
        '--theme-surface-5': '#9aabaf',
        '--theme-surface-6': '#bdbdbd',

        '--theme-component-1': '#cacaca',
        '--theme-component-2': '#ffffff',
        '--theme-component-3': '#f4f4f4',
        '--theme-component-4': '#0089a9',
        '--theme-component-6': '#eed668',
        '--theme-component-7': '#20515c',

        '--theme-outline-1': '#cacaca',
        '--theme-outline-2': '#ededed',
        '--theme-outline-3': '#9aabaf',
        '--theme-outline-4': '#f4f4f4',
        '--theme-outline-5': '#5d8b97',

        '--theme-text-primary': '#000000',
        '--theme-text-secondary': '#2c6d7c',
        '--theme-text-tertiary': '#5d8b97',
        '--theme-text-disabled': '#9aabaf',
        '--theme-text-inverted': '#f4f4f4',
        '--theme-text-labels': '#667579',
        '--text-on-dark': '#f4f4f4',
        '--theme-text-tooltips': '#232323',
        '--theme-text-subtitle': '#787878',

        '--theme-icon-1': '#2c6d7c',
        '--theme-icon-2': '#20515c',
        '--theme-icon-3': '#0097ba',
        '--theme-icon-4': '#e5e5e5',
        '--theme-icon-active': '#2c6d7c',
        '--theme-icon-inverted': '#ffffff',
        '--theme-icon-on-dark': '#f4f4f4',

        '--theme-waiting': '#979797',
        '--theme-processing': '#0097ba',
        '--theme-finished': '#5c9e43',

        '--headerPrimaryColor': '#20515c',
        '--headerSecondColor': '#2c6d7c',

        '--color-accent-1': '#0097ba',
        '--color-accent-2': '#2dc0e3',
        '--color-accent-3': '#60c0e4',

        '--color-green-ok': '#5c9e43',
        '--color-red-critical': "#cd3127",
        '--color-blue-info': "#0497ba",
        '--color-yellow-warning': '#e9c51c',
        '--color-grey-unknown': '#667579',
        '--color-purple-complete': '#783cbd',

        '--theme-drop-down-menu-1': '#0097ba',
        '--theme-drop-avatar-icon': '#2dc0e3',
        '--theme-drop-avatar-icon-back': '#7be6ff',

        '--theme-dropdown-menu': '#f4f4f4',
        '--theme-dropdown-menu-background': '#ffffff',
        '--theme-dropdown-menu-border': '#0097ba',
        '--theme-dropdown-menu-outline': '#cacaca',
        '--theme-dropdown-checkbox': '#ffffff',
        '--theme-dropdown-menu-item-hover': '#0097ba',
        '--theme-dropdown-menu-item-selected': '#e5e5e5',

        '--theme-dropdown-menu-avatar-text': '#ededed',
        '--theme-dropdown-hover': '#0097ba',
        '--theme-dropdown-selected': '#cacaca',

        '--theme-input-background': '#ffffff',
        '--theme-input-border-enabled': '#cacaca',
        '--theme-input-text-enabled': '#20515c',
        '--theme-input-border-active': '#0097ba',

        '--button-solid-logout': '#f4f4f4',
        '--button-solid-logout-text': '#20515c',
        '--button-solid-logout-hover': '#000000',
        '--button-solid-logout-text-hover': '#f4f4f4',
        '--button-solid-active': '#0097ba',
        '--button-solid-hover': '#2dc0e3',
        '--button-solid-focus': '#2c6d7c',
        '--button-solid-disabled': '#9aabaf',

        '--button-solid-text-enabled': '#ffffff',
        '--button-solid-text-disabled': '#f4f4f4',

        '--button-outline-background': '#f4f4f4',
        '--button-outline-enabled-border': '#5d8b97',
        '--button-outline-hover-border': '#0097ba',
        '--button-outline-focus': '#2c6d7c',
        '--button-outline-disabled-border': '#9aabaf',

        '--button-outline-text-enabled': '#5d8b97',
        '--button-outline-text-disabled': '#9aabaf',
        '--button-outline-text-hover': '#0097ba',
        '--button-outline-text-focus': '#ffffff',

        '--theme-checkbox-unchecked': '#ffffff',
        '--theme-checkbox-border': '#cacaca',
        '--theme-checkbox-border-hover': '#0097ba',
        '--theme-checkbox-border-focus': '#2c6d7c',
        '--theme-checkbox-disabled': '#e5e5e5',
        '--theme-checkbox-border-disabled': '#9aabaf',

        '--theme-checkbox-checked': '#0097ba',
        '--theme-checkbox-checked-border-hover': '#2dc0e3',
        '--theme-checkbox-checked-border-focus': '#2c6d7c',

        '--theme-border-1': '#0097ba',
        '--theme-calendar-background': '#ffffff',
        '--theme-calendar-headerText': '#20515c',
        '--theme-calendar-dateSpan': '#2dc0e3',

        '--theme-progress-background': '#ffffff',
        '--theme-progress-no-cad-bg': 'linear-gradient(93deg, #ededed, #cacaca 100%)',
        '--theme-progress-stripe-color': '#ebebeb',
        '--theme-progress-stripe-zero-color': '#b1b1b1',

        '--control-special-background': '#e5e5e5',
        '--control-special-hover': '#0097ba',
        '--control-special-selected': '#ffffff',
        '--control-special-border': '#ffffff',

        // collapse
        '--theme-collapse-item-background': '#ededed',

        '--filter-primary-color': 'brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(25%) hue-rotate(51deg) brightness(85%) contrast(108%)',
        '--filter-accent-color': 'brightness(0) saturate(100%) invert(56%) sepia(65%) saturate(5067%) hue-rotate(160deg) brightness(89%) contrast(102%)',
        '--filter-secondary-color': 'brightness(0) saturate(100%) invert(32%) sepia(86%) saturate(313%) hue-rotate(144deg) brightness(95%) contrast(90%)',
        '--filter-modalHeaderText-color': 'brightness(0) saturate(100%) invert(23%) sepia(81%) saturate(342%) hue-rotate(144deg) brightness(94%) contrast(92%)',
        '--filter-white-color': 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7498%) hue-rotate(53deg) brightness(103%) contrast(101%)'
    }
];