import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { withRouter } from "react-router-dom";
import { appConfig } from "./config";

class Auth0ProviderWithHistory extends React.Component {

  onRedirectCallback = (appState) => {
    this.props.history.push(appState?.returnTo || window.location.pathname);
  };

  render() {
    return (
      <Auth0Provider
        domain={appConfig.domain}
        clientId={appConfig.clientId}
        redirectUri={window.location.origin}
        onRedirectCallback={this.onRedirectCallback}
        audience={appConfig.audience}
      >
        {this.props.children}
      </Auth0Provider>
    );
  }
}

export default withRouter(Auth0ProviderWithHistory);
