import axios from 'axios';
import { Notification } from "@sfx/react-ui"
import { getToken, getTokenType } from '../redux/userSelector';
import store from '../redux/store'

const errorMsg = {
    '401': 'Unauthorized',
    '403': 'Operation denied',
    '500': 'Server error'
}
const handleError = (status) => {
    if (Number(status) !== 404) {
        const description = errorMsg[status] || 'An unknown error!'
        Notification.error({
            description
        });
    }
}
const api = axios.create({ headers: { 'Content-Type': 'application/json' } })

// http request interceptors
api.interceptors.request.use(
    config => {
        const userToken = getToken();
        const userTokenType = getTokenType();
        const userInfo = store.getState().user
        let apiHostUrl = ''
        if (userInfo && userInfo.profile) {
            apiHostUrl = userInfo.profile.PlatformUrl
        }
        config.baseURL = apiHostUrl;
        if (userToken) {
            config.headers.Authorization = `${userTokenType} ${userToken}`
        }
        return config
    },
    err => {
        return Promise.reject(err)
    })


// Add a response interceptor
api.interceptors.response.use(res => res, err => {
    //common place to handle errors, don't catch the http error yourself if you have no special case.
    //do the unify http error handling logic here.
    if (err && err.response && err.response.status) {
        handleError(err && err.response && err.response.status);
    }
    return Promise.reject(err);
});

export default api