import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserProfileApi } from '../services/user.service'

// get user data from API
export const fetchUsers = createAsyncThunk(
    'user/fetchUsers',
    async () => {
        try {
            const { data } = await getUserProfileApi()
            const { givenName: given_name, surname: family_name } = data
            return { given_name, family_name, name: `${given_name} ${family_name}`.trim() }
        } catch (error) {
            return null
        }
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState: { profile: { TotalSignIn: 1, firstLogin: false } },
    reducers: {
        loadUser: (state, action) => {
            let newUser = { ...action.payload }
            let { profile: { TotalSignIn } } = newUser
            const firstLogin = TotalSignIn === 1 && !localStorage.getItem('firstLogin')
            newUser.profile.firstLogin = firstLogin

            return newUser ? { ...state, ...newUser } : state
        },
        updateFirstLogin: (state, action) => {
            localStorage.setItem('firstLogin', action.payload)
            state.profile.firstLogin = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
            state.profile = { ...state.profile, ...payload }
        });
    }
})

const { actions, reducer } = userSlice
export const { loadUser, updateFirstLogin } = actions
export default reducer
