import LocalizedStrings from 'react-localization';
import en from './lang/en.json';

export const languageMapList = {
	en: 'en',
}

export const langDict = {
    en: "English"
}

export const getLocalizedStrings = () => {
    let stringsObj = {
        en: en,
    }

    return stringsObj;
}

export const translations = new LocalizedStrings(getLocalizedStrings());