import { combineReducers } from "redux";
import user from './userSlice';
import theme from './themeSlice'
import language from './languageSlice'
import solutions from './solutionsSlice'
export default combineReducers({
    user,
    theme,
    language,
    solutions
});