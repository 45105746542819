
import { createSelector } from '@reduxjs/toolkit'
import store from './store';

const getUser = () => {
    return store.getState().user
}

export const getFullUserName = user => {
    const { given_name: firstName, family_name: lastName } = user.profile || {}
    return `${firstName} ${lastName}`.trim()
}

export const getOrgName = user => user.profile && user.profile.OrgName

const getRoles = user => {
    const { role } = user.profile || {}
    return Array.isArray(role) ? role : [role]
}

export const getToken = () => getUser().access_token;

export const getTokenType = () => getUser().token_type;

export const userIsAdmin = createSelector(
    getRoles,
    (role) =>
        !!(role.find(r => {
            return r.toLowerCase() === 'accountadministrator' || r.toLowerCase() === 'assetadministrator';
        }))
)

export const userIsAccountAdmin = createSelector(
    getRoles,
    (role) =>
        !!(role.find(r => {
            return r.toLowerCase() === 'accountadministrator'
        }))
)






