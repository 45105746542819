import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSolutionsApi } from '../services/solution.service'

// get user data from API
export const fetchSolutions = createAsyncThunk(
    'solutions/fetchSolutions',
    async () => {
        try {
            const { data } = await getSolutionsApi()
            const solutions = data.map(r => {
                let slnIcon = r.images && r.images[0];
                let defaultIconUrl = r.defaultImageUrl;
                let solutionIconUrl = (slnIcon && slnIcon.url) ? slnIcon.url : defaultIconUrl;

                return {
                    url: r.clientUri ? r.clientUri : '/',
                    id: r.id,
                    name: r.name,
                    shortName: r.shortName,
                    img: solutionIconUrl
                };
            })
            return solutions
        } catch (error) {
            return []
        }
    }
)

const solutionsSlice = createSlice({
    name: 'solutions',
    initialState: [],
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchSolutions.fulfilled, (state, { payload }) => {
            return payload
        });
    }
})

const { reducer } = solutionsSlice
export default reducer
