import axios from 'axios';

export let appConfig = {};
export const appConfiguration = axios.get(process.env.PUBLIC_URL + '/config/app.config.json')
    .then(res => {
        appConfig = res.data;
        document.title = appConfig.SolutionTitle
        return appConfig;
    });

