import { createSlice } from '@reduxjs/toolkit';
import { translations } from '../utils/translations';

const languageSlice = createSlice({
    name: 'language',
    initialState: {
        currentLanguage: localStorage.getItem('language') || translations.getLanguage(),
    },
    reducers: {
        toggleLanguage: (state, action) => {
            translations.setLanguage(action.payload);
            localStorage.setItem('language', action.payload);
            return { currentLanguage: action.payload }
        }
    }
})



const { actions, reducer } = languageSlice
export const { toggleLanguage } = actions
export default reducer
